"use client";

import { JitsuProvider, useJitsu } from "@jitsu/jitsu-react";
import snakeCase from "lodash/snakeCase";
import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { getUserGeolocationInfo } from "lib/geolocationApi";
import { Geolocation } from "types/apiTypes";
import { RecordItem } from "types/common";

type AnalyticsProps = {
  geoLocationInfo?: Geolocation;
  geoLocationInfoLoaded: boolean;
};
type AnalyticsProviderProps = { children: ReactNode };

type IdentifyUserProps = {
  traits?: RecordItem;
  callback?: () => void;
  user?: RecordItem;
};

type TrackProps = {
  eventName: string;
  properties?: RecordItem;
  callback?: () => void;
  user?: RecordItem;
};

const isLocalDev = !process.env.NEXT_PUBLIC_VERCEL_URL;
const IS_PRODUCTION = process.env.NEXT_PUBLIC_VERCEL_ENV === "production";
const JITSU_SOLDAVE_HOST = process.env.NEXT_PUBLIC_JITSU_SOLDAVE_HOST_URL || "";

const AnalyticsContext = createContext<AnalyticsProps>({ geoLocationInfoLoaded: false });
const { Provider } = AnalyticsContext;

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const isGeoLocationInfoLoaded = useRef(false);
  const [geoLocationInfo, setGeoLocationInfo] = useState<Geolocation | undefined>();

  const fetchGeolocationInfo = async () => {
    try {
      const result = await getUserGeolocationInfo();
      setGeoLocationInfo(result);
    } catch (err) {
      console.error(err as Error);
    } finally {
      isGeoLocationInfoLoaded.current = true;
    }
  };

  useEffect(() => {
    if (isGeoLocationInfoLoaded.current || !IS_PRODUCTION) return;
    fetchGeolocationInfo();
  }, []);

  return (
    <Provider value={{ geoLocationInfo, geoLocationInfoLoaded: isGeoLocationInfoLoaded.current }}>
      <JitsuProvider options={{ host: JITSU_SOLDAVE_HOST }}>{children}</JitsuProvider>
    </Provider>
  );
};

// Analytics hook to be used with other components.
export const useAnalytics = () => {
  const { analytics: jitsuAnalytics } = useJitsu();
  const { geoLocationInfo, geoLocationInfoLoaded } = useContext(AnalyticsContext);

  const identify = useCallback(
    ({ traits, callback, user }: IdentifyUserProps = {}): void => {
      if (!user || isLocalDev) return; // Only proceed if there is a user object

      const userTraits = traits || {
        email: user.email,
        name: user.full_name,
        phone: user.phone,
        role: user.type
      };

      // Attempt to identify the user with Jitsu analytics
      void jitsuAnalytics
        .identify(user.user_id, userTraits, {
          context: {
            location: geoLocationInfo?.location,
            ip: geoLocationInfo?.ip
          }
        })
        .catch(console.error);
    },
    [geoLocationInfo, jitsuAnalytics]
  );

  const track = useCallback(
    ({ eventName, properties, user }: TrackProps) => {
      if (isLocalDev) return;

      if (user) {
        const userTraits = { email: user.email, name: user.full_name, phone: user.phone, role: user.type };
        const trackedProperties = { userId: user.user_id, ...properties, userTraits };

        void jitsuAnalytics
          .track(snakeCase(eventName), {
            context: {
              location: geoLocationInfo?.location,
              ip: geoLocationInfo?.ip
            },
            ...trackedProperties
          })
          .catch(console.error);
      } else {
        void jitsuAnalytics
          .track(snakeCase(eventName), {
            context: {
              location: geoLocationInfo?.location,
              ip: geoLocationInfo?.ip
            },
            ...properties
          })
          .catch(console.error);
      }
    },
    [geoLocationInfo, jitsuAnalytics]
  );

  return {
    identify,
    track,
    geoLocationInfo,
    geoLocationInfoLoaded
  };
};
